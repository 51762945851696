import React from 'react'
import { photoObjectFormatter } from './TVET';

import orthophoto_3up from "../../Assets/Images/Projects/3Upazila Orthophoto.png"
import rayerkathi from "../../Assets/Images/Projects/Rayerkathi Jamidar Bari.jpg"
import wooden_bridge from "../../Assets/Images/Projects/Wooden Bridge.jpg"
import common_household from "../../Assets/Images/Projects/Common Household Type in Pirojpur.jpg"




const PhysicalFeatureSurvey3up = () => {
    const projectInfo = {
        location: 'Pirojpur Sadar, Nesarabad and Nazirpur Upazila',
        title: `Physical Feature Survey and Data Collection,
       Land Use Survey and Data Collection, Topographic 
       Survey and Data Collection and Other Surveys and 
       Establishment of BM Pillar under “Preparation of 
       Development plan for 3 Upazilas (Pirojpur Sadar,
       Nesarabad and Nazirpur Upazila) of Pirojpur 
       District“`,
        client: "Urban Development Directorate (UDD)",
        project_Value: '3.40cr / $290,522.81',
        rendered_service: 'Development/Strategic Planning',
        description: `The project, titled "Preparation of Development Plan for 3 Upazilas (Pirojpur Sadar, Nesarabad, and Nazirpur Upazila) of Pirojpur District," commissioned by the Urban Development Directorate (UDD), entails a comprehensive array of survey and data collection tasks. These encompass a Physical Feature Survey, Land Use Survey, Topographic Survey, and other pertinent surveys essential for the establishment of a robust baseline understanding of the area's landscape and developmental needs.
Considering this fact, Tiller will undertake comprehensive surveys and data collection for the preparation of a development plan for Pirojpur District's three upazilas. These surveys include Physical Feature, Land Use, and Topographic surveys, alongside other specialized surveys as needed. These efforts aim to establish a thorough understanding of the region's landscape and developmental needs, facilitating informed decision-making for sustainable and inclusive development.`,
        list_up: [
            {
                content: `Conducting the pre- requisite activities of drone fly e.g.  UAV flight permission, no- drone zone demarcation, UAV flight path set and 
                flight block prepare, GCP marking and UAV flight planning.`},
            { content: `High-resolution images have been prepared by using Drone Technology and conducting the GCP survey for referencing the captured images by drone.` },
            { content: `Preparation of GCP collection, Tie point generation, image processing, orthorectification of images, feature extraction and digital mapping from stereo model.` }
        ],

        list_down: [
            { content: `Establishment of BM pillar for getting accurate and consistent coordinate systems for project area, Base Map Preparation by using Photogrammetric Method, DEM Preparation for topographic representation and hydrological modelling and App develop in digital platform to monitor the physical feature data collection activities.` },
            { content: `Physical feature survey data collection including existing structures, roads, bridges, drains, sewer systems, water supply, and utility facilities with precise 3-D coordinates and specifications;` },
            { content: `Primary data collection such as Physical Feature, Topographic, Land use, Transportation, Hydrological, Other related survey and other Studies (Economic Survey, bathymetric report studies, survey of Urban and Rural Economy, environment studies, disaster studies, social space studies etc. and secondary Data Collection (i.e., Socio Economic data) map Preparation.` },
        ],


        common_household_image: photoObjectFormatter(common_household, 'Common Household Type in Pirojpur'),
        rayerkathi_bari_image: photoObjectFormatter(rayerkathi, 'Rayerkathi Jamidar Bari'),
        wooden_bridge_image: photoObjectFormatter(wooden_bridge, 'Wooden Bridge'),

        professional_staff: [
            {
                name: `Tamzidul Islam`,
                designation: `Team Leader`,
            },
            {
                name: `Al-Ferdous Ahmed`,
                designation: `Urban Planner`,
            },
            {
                name: `Mehedi Mudasser`,
                designation: `Urban Planner`,
            },
            {
                name: `Tanvir Ahmed`,
                designation: `GIS Expert`,
            }, {
                name: `Tamanna Salam`,
                designation: `Survey Expert`,
            },

            {
                name: `Nasrin Sultana`,
                designation: `Photogrammetric Expert`,
            },
            {
                name: `Md. Mohaiminul Islam Rifat`,
                designation: `Jr. GIS Expert`,
            }, {
                name: `Sultana Ashrafi`,
                designation: `Jr. Urban Planner`,
            },
            {
                name: `Md. Rafiqul Islam`,
                designation: `Surveyor`,
            },
            {
                name: `Nahidul Islam`,
                designation: `GIS/RS Technician`,
            },
            {
                name: `Mashiat Noor-E-Zakir`,
                designation: `GIS/RS Technician`,
            },
            {
                name: `Moly Podder`,
                designation: `GIS/RS Technician`,
            },
        ],
    };

    const { title, location, client, project_Value,
        professional_staff,
        rendered_service, description, list_down, list_up, common_household_image,
        rayerkathi_bari_image, wooden_bridge_image
    } = projectInfo;
    const gridItems = [
        { title: "Location and Area", content: location },
        { title: "Project Value", content: project_Value },
        { title: "Client", content: client },
        { title: "Rendered Service", content: rendered_service },
    ];


    const GridItem = ({ items }) => {
        return (
            <div className="flex  justify-around items-center gap-5">
                {items.map((item, index) => (
                    <div key={index} className="text-center ">
                        <b>{item.title}</b> <br />
                        <small>{item.content}</small>
                    </div>
                ))}
            </div>
        )
    }

    return (
        <div className=''>
            <div className='relative '>
                <div className='h-[38.75rem]'>
                    <img src={orthophoto_3up} alt='physical_feature_Rangpur_Sylhet ' className='w-full h-full bg-cover sm:object-cover' />
                </div>
                <div className='px-[4.6762rem] py-[1.25rem] font-bold   bg-gray-800 text-white absolute bottom-0 min-h-[8.875rem] w-full  
           opacity-90 leading-[1.2] tracking-wide 2xl:texssst-[2.8219rem]  xl:texssst-[2.5219rem]  lg:text-[1.80rem]   md:text-[1.52rem]  '>
                    <p className=' text-justify'> {title}</p>
                </div>
            </div>

            <div className="bg-[#e0e0d8] p-10 opacity-80 ">
                <GridItem items={gridItems} />
            </div>
            <div className='px-[8%] '>
                <div className='my-[1.625rem] w-[22.875rem]'>
                    <b className='font-bold text-[#333333] text-[1.75rem]'>Project Activities</b>
                </div>
                <p className='leading-loose tracking-wide text-justify'>
                    {description}
                </p>
                
                <div className='my-[2.625rem] '>
                    <div className='h-[35rem]'>
                        <img src={common_household_image?.photo} alt={common_household_image?.caption} className='w-full h-full bg-cover sm:object-cover' />
                    </div>
                    <i>{common_household_image?.caption}</i>
                </div>

                <div className='py-5'>
                    <div className=' min-w-[22.875rem]'>
                        <b className='font-bold text-[#333333] text-[1.75rem]'>Service provided:</b>
                    </div>
                    <ul className='pl-2 list-disc leading-loose tracking-wide text-justify'>
                        {
                            list_up.map(({ content }, index) => (
                                <li key={index}>{content}</li>
                            ))
                        }


                    </ul>
                </div>







                {/* <div>
                    <p className='tracking-wider text-4xl leading-[2.625rem] text-justify text-[#84BE3F]'>
                        <strong className='font-extrabold '>{quote_2.substring(0, 14)}</strong>  {quote_2.substring(14)}</p>
                </div> */}
                <div className='flex items-center gap-5 '>
                    <div>
                        <img src={rayerkathi_bari_image?.photo} alt={rayerkathi_bari_image?.caption}
                            className='h-full  object-cover' />

                        <i>{rayerkathi_bari_image?.caption}</i>
                    </div>



                    <div>
                        <img src={wooden_bridge_image?.photo} alt={wooden_bridge_image?.caption}
                            className=' h-full object-cover ' />

                        <i>{wooden_bridge_image?.caption}</i>
                    </div>



                </div>

                <div>
                    <ul className='list-disc leading-loose tracking-wide text-justify'>

                        {
                            list_down.map(({ content }, index) => (
                                <li key={index}>{content}</li>
                            ))
                        }



                    </ul>
                </div>
                <div className='my-10'>
                    <strong className="text-lg ">Name of senior professional staff</strong>
                    <div className="flex flex-col gap-2 mt-4">
                        {professional_staff?.map(({ name, designation }) => (
                            <p>
                                <strong className='mr-1'>{name},</strong>{designation}
                            </p>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PhysicalFeatureSurvey3up