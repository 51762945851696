import React from "react";
import { photoObjectFormatter } from "./TVET";
import citizen_satisfaction_cities from "../../Assets/Images/Projects/citizen_satisfaction_cities.jpg";
import citizen_satisfaction_cover from "../../Assets/Images/Projects/citizen_satisfaction_cover.jpg";


const CitizenSatisfactionBaseline = () => {
  const projectInfo = {
    location: "Khulna, Rajshahi, Sirajganj, Satkhira, Bangladesh",
    title: `Conduction of a Citizen Satisfaction Baseline Study in Selected Neighbourhoods in Khulna, Rajshahi, Sirajganj, Satkhira`,
    client:
      "The Deutsche Gesellschaft für Internationale Zusammenarbeit GmbH (GIZ)",
    project_Value: "0.10 cr / $8995.47",
    rendered_service: "Survey/Baseline Study",
    description: `A central element of the LICA project is “Urban (Living) Labs” (ULL). In this framework, in the four city administrations of Khulna, Satkhira, Rajshahi and Sirajganj, small-scale demonstration projects for improved environmental management will be designed, implemented, and evaluated in selected areas in a participatory way with different stakeholders from civil society, universities and research, city administrations, etc. using innovative approaches. In each city, integrated measures (e.g., for institutional management, integrated technical concepts, needs orientation, more efficient workflows, adapted technologies, physical transformation, inclusive participation) related to the management of green spaces / blue spaces / solid waste will be developed and tested. The measures will be specific to its situation and needs in each city. The neighbourhoods (project areas) are selected by the LICA project together with the ULL implementing partners.`,
    list_up: [
      {
        content: `Outlined data collection and analysis approach using the LICA questionnaire;`,
      },
      {
        content: `Developed interview guidelines and defined team roles and responsibilities;`,
      },
      {
        content: `Created a detailed roadmap with key activities, milestones, and timelines for interviews and data analysis;`,
      },
      {
        content: `Conducted training sessions on the LICA questionnaire and data collection techniques;`,
      },
      {
        content: `Conducted 400 interviews across four cities (100 per city) with diverse demographics: 50% women, 25% youth (18-20 years), including migrants and persons with disabilities;`,
      },
      {
        content: `Gathered qualitative and quantitative data through structured interviews;`,
      },
      {
        content: `Verified findings with the LICA project team to ensure accuracy;`,
      },
      {
        content: `Prepared a draft report summarizing findings, with an executive summary and recommendations;`,
      },
      {
        content: `Incorporated stakeholder feedback into the final report;`,
      },
      {
        content: `Established a baseline indicator for urban environmental management service satisfaction;`,
      },
      {
        content: `Ensured representative sampling for accurate future comparisons.`,
      },
    ],

    baseline_cover: photoObjectFormatter(citizen_satisfaction_cover, "Cover Image"),

    cities: photoObjectFormatter(citizen_satisfaction_cities, "cities"),

    professional_staff: [
      {
        name: `Dr. Mohammad Zakir Hossain`,
        designation: `Lead Consultant`,
      },
    ],
  };

  const {
    title,
    location,
    client,
    project_Value,
    professional_staff,
    rendered_service,
    description,
    list_up,
    baseline_cover,
    cities
  } = projectInfo;
  const gridItems = [
    { title: "Location and Area", content: location },
    { title: "Project Value", content: project_Value },
    { title: "Client", content: client },
    { title: "Rendered Service", content: rendered_service },
  ];

  const GridItem = ({ items }) => {
    return (
      <div className="flex  justify-around items-center gap-5">
        {items.map((item, index) => (
          <div key={index} className="text-center ">
            <b>{item.title}</b> <br />
            <small>{item.content}</small>
          </div>
        ))}
      </div>
    );
  };

  return (
    <div className="">
      <div className="relative ">
        <div className="h-[38.75rem]">
          <img
            src={baseline_cover.photo}
            alt={baseline_cover.caption}
            className="w-full h-full bg-cover sm:object-cover"
          />
        </div>
        <div
          className="px-[4.6762rem] py-[1.25rem] font-bold   bg-gray-800 text-white absolute bottom-0 min-h-[8.875rem] w-full  
           opacity-90 leading-[1.2] tracking-wide 2xl:texssst-[2.8219rem]  xl:texssst-[2.5219rem]  lg:text-[1.80rem]   md:text-[1.52rem]  "
        >
          <p className=" text-justify"> {title}</p>
        </div>
      </div>

      <div className="bg-[#e0e0d8] p-10 opacity-80 ">
        <GridItem items={gridItems} />
      </div>
      <div className="px-[8%] ">
        <div className="my-[1.625rem] w-[22.875rem]">
          <b className="font-bold text-[#333333] text-[1.75rem]">
            Project Activities
          </b>
        </div>
        <p className="leading-loose tracking-wide text-justify">
          {description}
        </p>

        <div className="my-[1.625rem] ">
          <div className="h-[46rem]">
            <img
              src={cities?.photo}
              alt={cities?.caption}
              className="w-full h-full bg-cover sm:object-cover"
            />
          </div>
          <i>{cities?.caption}</i>
        </div>

       
        <div className="my-[1.625rem] min-w-[22.875rem]">
          <b className="font-bold text-[#333333] text-[1.75rem]">
            Service provided:
          </b>
        </div>
        <div>
          <ul className="list-disc leading-loose tracking-wide text-justify">
            {list_up.map(({ content }, index) => (
              <li key={index}>{content}</li>
            ))}
          </ul>
        </div>

       

        

        <div className="my-10">
          <strong className="text-lg ">
            Name of senior professional staff
          </strong>
          <div className="flex flex-col gap-2 mt-4">
            {professional_staff?.map(({ name, designation }) => (
              <p>
                <strong className="mr-1">{name},</strong>
                {designation}
              </p>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CitizenSatisfactionBaseline;
