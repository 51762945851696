import React from 'react'
import { photoObjectFormatter } from './TVET';
import bm_location_image from "../../Assets/Images/Projects/BM Location.jpg"
import gorh_para_mosque_image from "../../Assets/Images/Projects/Gorh Para Mosque.jpeg"
import meherpur_city_image from "../../Assets/Images/Projects/Meherpur City.jpg"
import gorh_pond_image from "../../Assets/Images/Projects/Gorh Pond.jpeg"
import mujibnagar_image from "../../Assets/Images/Projects/Mujibnagar.jpg"






const PhysicalFeatureMeherPur = () => {
    const projectInfo = {
        location: 'Meherpur, Gangni',
        title: `Preparation of UAV based Physical feature, Topographic and Landuse GIS Database, Mouza map collection. Scanning, digitization, editing and Printing under Preparation of Development Plan for Meherpur Zilla; Package-01`,
        client: "Urban Development Directorate (UDD)",
        project_Value: '7.77cr / $662,673.24',
        rendered_service: 'Development/Strategic Planning',
        description: `The project "Preparation of UAV based Physical feature, Topographic and Landuse GIS Database, Mouza map collection. Scanning, digitization, editing, and Printing under Preparation of Development Plan for Meherpur Zilla; Package-01" is an ambitious initiative commissioned by the Urban Development Directorate (UDD). This project aims to establish a comprehensive and precise GIS database that details physical features, topography, and land use patterns through advanced UAV technology. By integrating high-precision data collection and digitization of Mouza maps, this initiative sets a new benchmark for regional planning, ensuring a robust framework for informed decision-making and strategic development.
Tiller will execute this project, focuses on the scanning, digitization, editing, and printing of Mouza maps, which are critical components for creating an accessible and detailed geographic database. This project not only addresses the developmental challenges of Meherpur Zilla but also provides a clear and actionable plan for sustainable growth. Leveraging cutting-edge technology, Tiller's efforts will facilitate equitable resource distribution and strategic urban planning, aligning with the broader objectives of regional economic and social development.
`,
        list_up: [
            {
                content: `Conducting the pre- requisite activities of drone fly e.g.  UAV flight permission, no- drone zone demarcation, UAV flight path set and flight block prepare, CGP marking and UAV flight planning.`
            },
            { content: `High-resolution images have been prepared by using Drone Technology and conducting the GCP survey for referencing the captured images by drone.` },
            { content: `Preparation of GCP collection, Tie point generation, image processing, orthorectification of images, feature extraction and digital mapping from stereo model.` },

            { content: `Establishment of BM pillar for getting accurate and consistent coordinate systems for project area, Base Map Preparation by using Photogrammetric Method, DEM Preparation for topographic representation and hydrological modelling and App develop in digital platform to monitor the physical feature data collection activities.` },
        ],


        list_down: [
            { content: `Physical feature survey data collection including existing structures, roads, bridges, drains, sewer systems, water supply, and utility facilities with precise 3-D coordinates and specifications;` },
            { content: `Primary data collection such as Physical Feature, Topographic, Land use, Transportation, Hydrological, Other related survey and other Studies (Economic Survey, bathymetric report studies, survey of Urban and Rural Economy, environment studies, disaster studies, social spacestudies etc. and secondary Data Collection (i.e., Socio Economic data) map Preparation.` },
        ],


        "_bm_location_image": photoObjectFormatter(bm_location_image, 'BM Location'),
        "_gorh_para_mosque_image": photoObjectFormatter(gorh_para_mosque_image, 'Gorh Para Mosque'),
        "_meherpur_city_image": photoObjectFormatter(meherpur_city_image, 'Meherpur City'),
        "_gorh_pond_image": photoObjectFormatter(gorh_pond_image, 'Gorh Pond'),
        "_mujibnagar_image": photoObjectFormatter(mujibnagar_image, 'Mujibnagar'),



        professional_staff: [
            {
                name: `Tamzidul Islam`,
                designation: `Team Lead/ GIS Expert`,
            },
            {
                name: `Tanjimul Islam Jiban`,
                designation: `Survey Expert	`,
            }, {
                name: `Nasrin Sultana`,
                designation: `Photogrematry Expert`,
            },

            {
                name: `Al-Ferdous Ahmed`,
                designation: `Junior Urban Planner`,
            },
            {
                name: `Muhammad Waresul Hassan Nipun`,
                designation: `Junior Urban Planner`,
            },
            {
                name: `Shatabdy Das`,
                designation: `Junior GIS Expert`,
            },
            {
                name: `Mohammad Zillur Rahman`,
                designation: `Junior Architect`,
            },
            {
                name: `Nahidul Islam`,
                designation: `GIS/RS Technician`,
            },
            {
                name: `Mashiat Noor-E-Zakir, `,
                designation: `GIS/RS Technician`,
            },
            {
                name: `Moly Podder`,
                designation: `GIS/RS Technician`,
            },
        ],
    };

    const { title, location, client, project_Value,
        professional_staff,
        rendered_service, description, list_down, list_up,
        _bm_location_image,
        _gorh_para_mosque_image,
        _meherpur_city_image,
        _gorh_pond_image,
        _mujibnagar_image

    } = projectInfo;
    const gridItems = [
        { title: "Location and Area", content: location },
        { title: "Project Value", content: project_Value },
        { title: "Client", content: client },
        { title: "Rendered Service", content: rendered_service },
    ];


    const GridItem = ({ items }) => {
        return (
            <div className="flex  justify-around items-center gap-5">
                {items.map((item, index) => (
                    <div key={index} className="text-center ">
                        <b>{item.title}</b> <br />
                        <small>{item.content}</small>
                    </div>
                ))}
            </div>
        )
    }

    return (
        <div className=''>
            <div className='relative '>
                <div className='h-[38.75rem]'>
                    <img src={meherpur_city_image} alt='physical_feature_Rangpur_Sylhet ' className='w-full h-full bg-cover sm:object-cover' />
                </div>
                <div className='px-[4.6762rem] py-[1.25rem] font-bold   bg-gray-800 text-white absolute bottom-0 min-h-[8.875rem] w-full  
           opacity-90 leading-[1.2] tracking-wide 2xl:texssst-[2.8219rem]  xl:texssst-[2.5219rem]  lg:text-[1.80rem]   md:text-[1.52rem]  '>
                    <p className=' text-justify'> {title}</p>
                </div>
            </div>

            <div className="bg-[#e0e0d8] p-10 opacity-80 ">
                <GridItem items={gridItems} />
            </div>
            <div className='px-[8%] '>
                <div className='my-[1.625rem] w-[22.875rem]'>
                    <b className='font-bold text-[#333333] text-[1.75rem]'>Project Activities</b>
                </div>
                <p className='leading-loose tracking-wide text-justify'>
                    {description}
                </p>



                <div className='my-[1.625rem] '>
                    <div className='h-[46rem]'>

                        <img src={_mujibnagar_image?.photo} alt={_mujibnagar_image?.caption} className='w-full h-full bg-cover sm:object-cover' />
                    </div>
                    <i>{_mujibnagar_image?.caption}</i>
                </div>

                {/* <div className='my-[1.625rem] '>
                    <div className='h-[25rem]'>

                        <img src={study_area_image?.photo} alt={study_area_image?.caption} className='w-full h-full bg-cover sm:object-cover' />
                    </div>
                    <i>{study_area_image?.caption}</i>
                </div> */}
                <div className='my-[1.625rem] min-w-[22.875rem]'>
                    <b className='font-bold text-[#333333] text-[1.75rem]'>Service provided:</b>
                </div>
                <div>
                    <ul className='list-disc leading-loose tracking-wide text-justify'>
                        {
                            list_up.map(({ content }, index) => (
                                <li key={index}>{content}</li>
                            ))
                        }


                    </ul>
                </div>


                <div className='my-[1.625rem] w-full'>
                    <div className='h-[35rem]'>
                        <img src={_bm_location_image?.photo} alt={_bm_location_image?.caption}
                            className='w-full h-full  object-contain ' />
                    </div>
                    <p className='text-center italic'>{_bm_location_image?.caption}</p>
                </div>





                <div>
                    <ul className='list-disc leading-loose tracking-wide text-justify'>

                        {
                            list_down.map(({ content }, index) => (
                                <li key={index}>{content}</li>
                            ))
                        }
                    </ul>
                </div>

                <div className='my-[2.625rem] '>
                    <div className='h-[28rem]'>
                        <img src={_gorh_pond_image?.photo} alt={_gorh_pond_image?.caption} className='w-full h-full bg-cover sm:object-cover' />
                    </div>
                    <i>{_gorh_pond_image?.caption}</i>
                </div>

                <div className='my-[2.625rem] '>
                    <div className='h-[42rem] '>
                        <img src={_gorh_para_mosque_image?.photo} alt={_gorh_para_mosque_image?.caption} className='w-full h-full bg-cover sm:object-cover' />
                    </div>
                    <i>{_gorh_para_mosque_image?.caption}</i>
                </div>

                <div className='my-10'>
                    <strong className="text-lg ">Name of senior professional staff</strong>
                    <div className="flex flex-col gap-2 mt-4">
                        {professional_staff?.map(({ name, designation }) => (
                            <p>
                                <strong className='mr-1'>{name},</strong>{designation}
                            </p>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PhysicalFeatureMeherPur